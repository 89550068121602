import React, { useState, useEffect } from 'react';
import { Router } from '@reach/router';

import fetchJsonp from 'fetch-jsonp';

import VacancyPage from '../templates/VacancyPage';
import GroupVacanciesPage from '../templates/GroupVacanciesPage';
import SmartFeedSavedList from '../components/SmartFeedSavedJobsList';
import Loader from '../components/Loader';

const Vacancies = (props) => {
  const {
    pageContext: { config },
    location,
  } = props;

  const siteConfig = config;

  const [apiKey, setApiKey] = useState(
    props.smartfeedID || siteConfig.apiKey || siteConfig.smartfeedID
  );
  const [gdprKey, setGdprKey] = useState(
    props.smartfeedID ||
      siteConfig.gdprKey ||
      siteConfig.apiKey ||
      siteConfig.smartfeedID
  );
  const [groupOrIdParam, setGroupOrIdParam] = useState(
    siteConfig.group ? 'group' : 'id'
  );
  const [smartfeedCompanyData, setSmartfeedCompanyData] = useState({});
  const [appGatewayURL, setAppGatewayURL] = useState({});
  const [vacanciesRouteLoading, setVacanciesRouteLoading] = useState(true);
  const [vacanciesRouteError, setVacanciesRouteError] = useState(null);

  useEffect(() => {
    if (apiKey) {
      let companyDetailsEndpoint = 'GetCompanyDetails';

      if (groupOrIdParam === 'group') {
        companyDetailsEndpoint = 'GetGroupDetails';
      }

      try {
        fetchJsonp(
          siteConfig.sfAPIEndpoint +
            '/CareerPage/' +
            companyDetailsEndpoint +
            '?id=' +
            apiKey,
          {
            timeout: 15000,
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (jsonList) {
            if (companyDetailsEndpoint !== 'GetGroupDetails') {
              fetchJsonp(
                siteConfig.sfAPIEndpoint +
                  '/CareerPage/' +
                  'GetApplicantGetwayURL' +
                  '?companyId' +
                  '=' +
                  gdprKey,
                {
                  timeout: 15000,
                }
              )
                .then(function (response) {
                  return response.json();
                })
                .then(function (appgatway) {
                  setVacanciesRouteLoading(false);
                  if (
                    jsonList &&
                    jsonList.CompanyDetails &&
                    jsonList.CompanyDetails.length > 0
                  ) {
                    setSmartfeedCompanyData(jsonList.CompanyDetails[0]);
                  } else {
                    setSmartfeedCompanyData(jsonList);
                  }
                  setAppGatewayURL(appgatway);
                })
                .catch(function (ex) {
                  setVacanciesRouteLoading(false);
                  setVacanciesRouteError(ex);
                  console.error(ex, 'error');
                });
            } else {
              setVacanciesRouteLoading(false);
              if (
                jsonList &&
                jsonList.CompanyDetails &&
                jsonList.CompanyDetails.length > 0
              ) {
                setSmartfeedCompanyData(jsonList.CompanyDetails[0]);
              } else {
                setSmartfeedCompanyData(jsonList);
              }
              setAppGatewayURL(null);
            }
          })
          .catch(function (ex) {
            setVacanciesRouteLoading(false);
            setVacanciesRouteError(ex);
            console.error(ex, 'error');
          });
      } catch (ex) {
        setVacanciesRouteLoading(false);
        setVacanciesRouteError(ex);
        console.error(ex, 'error');
      }
    } else {
      setVacanciesRouteLoading(false);
      setVacanciesRouteError('No API key was provided.');
    }
  }, [apiKey, groupOrIdParam]);

  return (
    <Router>
      <Loader
        siteConfig={siteConfig}
        location={location}
        apiKey={apiKey}
        groupOrIdParam={groupOrIdParam}
        smartfeedCompanyData={smartfeedCompanyData}
        appGatewayURL={appGatewayURL}
        vacanciesRouteLoading={vacanciesRouteLoading}
        vacanciesRouteError={vacanciesRouteError}
        useGroupVacsLink={siteConfig.group}
        default
      />
      <VacancyPage
        siteConfig={siteConfig}
        location={location}
        apiKey={apiKey}
        groupOrIdParam={groupOrIdParam}
        smartfeedCompanyData={smartfeedCompanyData}
        appGatewayURL={appGatewayURL}
        vacanciesRouteLoading={vacanciesRouteLoading}
        vacanciesRouteError={vacanciesRouteError}
        path="/group-vacancies/vacancy/:id"
        useGroupVacsLink={siteConfig.group}
      />
      <VacancyPage
        siteConfig={siteConfig}
        location={location}
        apiKey={apiKey}
        groupOrIdParam={groupOrIdParam}
        smartfeedCompanyData={smartfeedCompanyData}
        appGatewayURL={appGatewayURL}
        vacanciesRouteLoading={vacanciesRouteLoading}
        vacanciesRouteError={vacanciesRouteError}
        path="/group-vacancies/vacancy/:id/:vacancyTitlePath"
        useGroupVacsLink={siteConfig.group}
      />
      <SmartFeedSavedList
        siteConfig={siteConfig}
        location={location}
        apiKey={apiKey}
        groupOrIdParam={groupOrIdParam}
        smartfeedCompanyData={smartfeedCompanyData}
        appGatewayURL={appGatewayURL}
        vacanciesRouteLoading={vacanciesRouteLoading}
        vacanciesRouteError={vacanciesRouteError}
        path="/group-vacancies/saved"
        useGroupVacsLink={siteConfig.group}
      />
      <GroupVacanciesPage
        siteConfig={siteConfig}
        urlLocation={location}
        apiKey={apiKey}
        groupOrIdParam={groupOrIdParam}
        smartfeedCompanyData={smartfeedCompanyData}
        appGatewayURL={appGatewayURL}
        vacanciesRouteLoading={vacanciesRouteLoading}
        vacanciesRouteError={vacanciesRouteError}
        path="/group-vacancies/"
      />
    </Router>
  );
};

export default Vacancies;
